body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiToolbar-root {
    color: #d9d9d9;
}
.MuiSvgIcon-root,
.MuiIconButton-label,
.MuiDataGrid-sortIcon {
    color: #d9d9d9;
}

.MuiDataGrid-footer,
.MuiToolbar-regular,
.MuiTablePagination-toolbar,
.MuiToolbar-gutters,
.MuiTablePagination-actions,
.MuiTablePagination-caption,
.MuiDataGrid-selectedRowCount {
    display: none;
}

.MuiDataGrid-scrollArea,
.MuiDataGrid-scrollAreaLeft,
.MuiDataGrid-scrollAreaRight {
    display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
